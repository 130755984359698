<template>
  <div class="products">
    <div class="product-body">
      <b-alert v-if="!rows.length" show variant="warning" class="text-center m-2">
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>

      <div class="p-2" v-else>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :sort-options="{
            enabled: false,
          }"
          :expandOptions="{ enabled: true, selfManaged: true }"
          :expandedRows="[1, 4, 12, 54]"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'uye_acente'">
              <router-link
                :to="{
                  name: 'destek-hatti-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                <template v-if="props.row.ac_k_no">
                  <b-badge variant="info" class="d-block mt-1 rounded-0 text-left">
                    {{ props.row.acente.firma_adi }}
                  </b-badge>
                </template>
                <template v-else>
                  <b-badge variant="warning" class="d-block mt-1 rounded-0 text-left">
                    {{ props.row.uye.ad }} {{ props.row.uye.soyad }}
                  </b-badge>
                </template>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'talep_no'">
              <router-link
                :to="{
                  name: 'destek-hatti-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                  {{ props.row.talep_no }}
                </b-badge>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'departman'">
              <router-link
                :to="{
                  name: 'destek-hatti-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                <span class="rounded-0 bg-warning p-1 text-white d-block text-left">
                  {{ departmanText(props.row.departman) }}
                </span>
                <span class="rounded-0 p-1 text-white d-block text-left" :style="`background-color:${onemDereceColor(props.row.oncelik)}`">
                  {{ onemDereceText(props.row.oncelik) }}
                </span>
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'konu'">
              <router-link
                :to="{
                  name: 'destek-hatti-detay',
                  params: { k_no: props.row.k_no },
                }"
              >
                {{ props.row.konu }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <span
                class="rounded-0 p-1 text-white d-block text-center"
                :class="props.row.statu == 'yanit_bekliyor' ? 'bg-warning' : props.row.statu == 'cevaplandi' ? 'bg-success' : 'bg-danger'"
              >
                {{ statuText(props.row.statu) }}
              </span>
            </span>
            <span v-else-if="props.column.field === 'tarih'">
              <span class="rounded-0 bg-warning p-1 text-white d-block text-right pr-2">
                {{ props.row.save_date | momentText }}
              </span>
              <span class="rounded-0 bg-info p-1 text-white d-block text-right pr-2">
                {{ props.row.edit_date | momentText }}
              </span>
            </span>
            <span v-else-if="props.column.field === 'action'" class="float-right px-1">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret menu-class="w-100">
                <template #button-content>
                  <i class="fad fa-ellipsis-v-alt"></i>
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'destek-hatti-detay',
                    params: { k_no: props.row.k_no },
                  }"
                >
                  <i class="fad fa-eye" /> Detay
                </b-dropdown-item>

                <b-dropdown-item @click="handlerRemove(props.row.k_no)"> <i class="fad fa-trash" /> Sil</b-dropdown-item>
              </b-dropdown>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="expanded-row" slot-scope="props">
            <div>
              {{ props.row }}
            </div>
          </template>
          <template slot="pagination-bottom">
            <Pagination
              :urunler="rows"
              :count="count"
              @handlerPageSizeChange="handlerPageSizeChange($event)"
              @handlerPageChange="handlerPageChange($event)"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';

import Pagination from './component/Pagination.vue';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    Pagination,
  },
  props: {
    filtered: [Boolean],
    itemView: [String],
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.pageLength = ref(20);
    expo.perPageOptions = ref([10, 20, 50, 100]);
    expo.columns = ref([
      {
        label: 'Uye | Acente',
        field: 'uye_acente',
        width: '12%',
      },
      {
        label: 'Talep No',
        field: 'talep_no',
        width: '9%',
      },
      {
        label: 'Departman',
        field: 'departman',
        width: '13%',
      },
      {
        label: 'Konu',
        field: 'konu',
      },
      {
        label: 'Statü',
        field: 'statu',
        width: '10%',
        tdClass: 'text-right',
      },
      {
        label: 'Oluşturma Güncelleme Tarihi',
        field: 'tarih',
        width: '18%',
        tdClass: 'text-right',
      },
      {
        field: 'action',
        width: '3%',
      },
    ]);

    expo.rows = ref([]);
    expo.count = ref(0);

    expo.departmanText = computed(() => {
      const statusText = {
        satis: 'Satış',
        muhasebe: 'Muhasebe',
        teknik_destek: 'Teknik Destek',
        oneri_sikayet: 'Öneri Şikayet',
        diger: 'Diğer',
      };
      return (status) => statusText[status];
    });

    expo.onemDereceText = computed(() => {
      const statusText = {
        dusuk: 'Düşük',
        normal: 'Normal',
        acil: 'Acil',
        cok_acil: 'Çok Acil',
      };
      return (status) => statusText[status];
    });

    expo.onemDereceColor = computed(() => {
      const statusText = {
        dusuk: '#03C200',
        normal: '#B07E00',
        acil: '#E85100',
        cok_acil: '#FF0000',
      };
      return (status) => statusText[status];
    });

    expo.statuText = computed(() => {
      const statusText = {
        yanit_bekliyor: 'Yanıt Bekliyor',
        cevaplandi: 'Cevaplandı',
        kapandi: 'Kapandı',
      };
      return (status) => statusText[status];
    });

    expo.handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('pageTitle', 'Destek Hattı');

      route.value.query.page = route.value.query.page ? parseInt(route.value.query.page) : 1;
      route.value.query.page_size = route.value.query.page_size ? parseInt(route.value.query.page_size) : 20;

      await store.dispatch('destekHattiListele', route.value.query).then((res) => {
        expo.rows.value = res.data.data;
        expo.count.value = res.data.count;
        context.emit('total', `${expo.count.value} kayıttan ${expo.rows.value.length} `);
        context.emit('show', false);
        // context.emit('filtered', false);
      });
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('destekHattiSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
              let index = expo.rows.value.findIndex((x) => x.k_no == k_no);
              expo.rows.value.splice(index, 1);
            }
          });
        }
      });
    };

    watch(
      route,
      (val) => {
        expo.handlerFetchAllData();
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
